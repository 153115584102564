import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat) // needed for custom date parsing
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(LocalizedFormat)

export default function useDisplayFormatters() {
  const formatItemField = (item, field, format) => {
    //get the actual field name after we added the "item." to the front of it for the v-slot
    var fieldName = field
    var fieldSplit = field.split('.')
    if (fieldSplit[1]) {
      fieldName = fieldSplit[1]
    }
    var splitFormat = format.split('|')
    switch (splitFormat[0].toLowerCase()) {
      case 'date':
        var formatFrom = undefined
        var formatTo = 'L'
        if (splitFormat[1]) {
          formatTo = splitFormat[1]
        }
        if (splitFormat[2]) {
          formatFrom = splitFormat[2]
        }
        if (item[fieldName] && item[fieldName].trim()) {
          return formatDate(item[fieldName], formatTo, formatFrom)
        } else {
          return ''
        }
      case 'percent':
        if (item[fieldName]) {
          return formatPercent(item[fieldName], splitFormat[1])
        } else {
          return ''
        }
      case 'decimal':
        if (item[fieldName]) {
          return formatDecimal(item[fieldName], splitFormat[1])
        } else {
          return ''
        }
      case 'money':
        if (item[fieldName]) {
          return formatMoney(item[fieldName], splitFormat[1])
        } else {
          return ''
        }
      default:
        return item[fieldName]
    }
  }
  const formatDate = (item, formatTo, formatFrom) => {
    return dayjs(item, formatFrom).format(formatTo)
  }
  const formatPercent = (item, decimalPlaces = 2) => {
    return (item * 100).toFixed(decimalPlaces) + '%'
  }
  const formatDecimal = (item, decimalPlaces = 2) => {
    return item.toFixed(decimalPlaces)
  }
  const formatMoney = (item, decimalPlaces = 2) => {
    let amt = parseFloat(item.toFixed(decimalPlaces))
    if (amt < 0) {
      return (
        '(' +
        ((amt &&
          Math.abs(amt)
            .toLocaleString('en-US', {style: 'currency', currency: 'USD'})
            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')) ||
          '0') +
        ')'
      )
    }
    return (
      (amt &&
        amt
          .toLocaleString('en-US', {style: 'currency', currency: 'USD'})
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')) ||
      '0'
    )
  }
  const splitTitleCase = (input) => {
    var split = input.replace(/[_-]/g, ' ')
    split = split.replace(/(\B[A-Z][a-z])/g, ' $1')
    split = split.replace(/([a-z])([A-Z])/g, '$1 $2')
    split = split.replace(/([^\d\s])([\d])/g, '$1 $2')
    split = split.replace(/([\d])([^\d\s])/g, '$1 $2')
    split = split.replace(/^l\s?u\b\s/gi, '')
    split = split.replace(/^v\s?w\b\s/gi, '')
    split = split.replace(/(^|\s)\S/g, function(t) {
      return t.toUpperCase()
    })
    // console.blue(input, split)
    return split
  }
  const splitLowerCase = (input) => {
    var split = input.replace(/[_-]/g, ' ')
    split = split.replace(/(\B[A-Z][a-z])/g, ' $1')
    split = split.replace(/([a-z])([A-Z])/g, '$1 $2')
    split = split.replace(/([^\d\s])([\d])/g, '$1 $2')
    split = split.replace(/([\d])([^\d\s])/g, '$1 $2')
    split = split.replace(/^l\s?u\b\s/gi, '')
    split = split.replace(/^v\s?w\b\s/gi, '')
    // console.blue(input, split)
    return split.toLowerCase()
  }

  return {
    formatItemField,
    formatDate,
    formatPercent,
    formatDecimal,
    formatMoney,
    splitTitleCase,
    splitLowerCase,
  }
}
