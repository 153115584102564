import { VCol } from 'vuetify/lib/components/VGrid';
import { VInput } from 'vuetify/lib/components/VInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_vm._l((_vm.mappedParameters),function(p,index){return _c(VCol,{key:index,attrs:{"sm":"12","md":"3"}},[(p.type == 'Date' || p.type == 'DateTime')?_c('span',[_c('BaseDatePickerWithText',{attrs:{"label":p.label,"clearable":!p.required},on:{"change":function($event){return _vm.updateValue($event, p)}},model:{value:(p.value),callback:function ($$v) {_vm.$set(p, "value", $$v)},expression:"p.value"}})],1):_vm._e(),(p.type == 'text')?_c('span',[_c(VTextField,{attrs:{"label":p.label,"clearable":""},on:{"change":function($event){return _vm.updateValue($event, p)}},model:{value:(p.model),callback:function ($$v) {_vm.$set(p, "model", $$v)},expression:"p.model"}})],1):_vm._e(),(p.type == 'lookup' && p.options.list)?_c('span',[_c(VSelect,{attrs:{"label":p.label,"items":p.options.list,"chips":p.options.multiple,"deletable-chips":p.options.multiple,"small-chips":p.options.multiple,"multiple":p.options.multiple,"clearable":!p.required,"rules":p.required ? [function (v) { return !!v || 'required'; }] : undefined},on:{"change":function($event){return _vm.updateValue($event, p)}},model:{value:(p.value),callback:function ($$v) {_vm.$set(p, "value", $$v)},expression:"p.value"}})],1):_vm._e(),(p.type == 'lookup' && p.name == 'projectId')?_c('span',[_c('Project',{attrs:{"division":_vm.currentUserDivision,"filter":function (item, queryText, itemText) {
            return (
              item.ProjectNumber.toLocaleLowerCase().indexOf(
                queryText.toLocaleLowerCase()
              ) > -1 ||
              item.Description.toLocaleLowerCase().indexOf(
                queryText.toLocaleLowerCase()
              ) > -1
            )
          },"rules":p.required ? [function (v) { return !!v || 'required'; }] : undefined},on:{"change":function($event){return _vm.updateValue($event, p)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('span',[_vm._v(_vm._s(item.ProjectNumber)+" - "+_vm._s(item.Description))])]}}],null,true),model:{value:(p.value),callback:function ($$v) {_vm.$set(p, "value", $$v)},expression:"p.value"}})],1):_vm._e()])}),_vm._l((_vm.groupValidations),function(item){return _c(VCol,[_c(VInput,{attrs:{"rules":[item.rule]}})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }