<template>
  <BasePanelWithHeader headerText="Canned Reports">
    <v-row>
      <v-col>
        <v-select
          label="Select Report"
          :items="availableReports"
          return-object
          v-model="selectedReport"
          item-text="Description"
        >
        </v-select>
      </v-col>
      <downloadButtons
        v-if="
          !selectedReport.isLegacy &&
            selectedCannedReport.FileTypes &&
            selectedCannedReport.FileTypes.length > 0
        "
        :fileTypes="selectedCannedReport.FileTypes"
        @downloadReport="downloadReport"
      ></downloadButtons>
      <v-col v-if="selectedReport.isLegacy">
        <v-btn @click="getCannedReport" color="primary">
          <v-icon left>mdi-file-chart</v-icon>Generate Report
        </v-btn>
      </v-col>
    </v-row>
    <v-form ref="form" @submit.prevent v-model="valid" lazy-validation>
      <reportParameters
        v-if="reportParameters.length > 0"
        :reportParameters="reportParameters"
        :searchParams="searchParams"
        @updateSearchParam="updateSearchParam"
      ></reportParameters>
      <v-row v-if="selectedReport.isLegacy">
        <v-col v-if="selectedReport.ShowMonths" sm="4">
          <v-select
            :items="monthList"
            item-value="index"
            v-model="selectedMonth"
            label="Month"
            :rules="[(v) => !!v || 'required']"
            clearable
          ></v-select>
        </v-col>
        <v-col v-if="selectedReport.ShowYears" sm="4">
          <v-select
            :items="yearList"
            v-model="selectedYear"
            label="Year"
            :rules="[(v) => !!v || 'required']"
            clearable
          ></v-select>
        </v-col>
        <v-col
          v-if="
            selectedReport.Id > 0 &&
              !selectedReport.ShowMonths &&
              !selectedReport.ShowYears
          "
          sm="12"
          md="3"
        >
          <BaseDatePickerWithText
            label="Start Date"
            v-model="startDate"
          ></BaseDatePickerWithText>
        </v-col>
        <v-col
          v-if="
            selectedReport.Id > 0 &&
              !selectedReport.ShowMonths &&
              !selectedReport.ShowYears
          "
          sm="12"
          md="3"
        >
          <BaseDatePickerWithText
            label="End Date"
            v-model="endDate"
          ></BaseDatePickerWithText>
        </v-col>
        <v-col v-if="selectedReport.Id > 0 && selectedReport.ShowDetails">
          <v-checkbox v-model="includeDetails" label="As Pivot?"></v-checkbox>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col sm="12" md="6">
        <v-select
          v-if="
            selectedCannedReport &&
              selectedCannedReport.reportDefaults &&
              selectedCannedReport.reportDefaults.ReportSections &&
              selectedCannedReport.reportDefaults.ReportSections.length > 0
          "
          label="Show Section"
          :items="selectedCannedReport.reportDefaults.ReportSections"
          v-model="sectionId"
          item-text="Name"
          item-value="ID"
        >
        </v-select>
      </v-col>
    </v-row>
    <BaseServerSyncDataTable
      v-if="selectedCannedReport.ID"
      :headers="filteredHeaders"
      :items="gridData"
      :searchTotal="searchTotal"
      :loading="loading"
      :searchSortBy.sync="searchSortBy"
      :searchDescending.sync="searchDescending"
      :searchPage.sync="searchPage"
      :searchRowsPerPage.sync="searchRowsPerPage"
      multi-sort
      :show-group-by="showGroupBy"
      :groupBy.sync="groupBy"
      :groupDesc.sync="groupByDescending"
    >
      <template
        v-for="(format, field) in mappedFormats"
        v-slot:[field]="{item}"
        >{{ formatItemField(item, field, format) }}</template
      >
    </BaseServerSyncDataTable>
  </BasePanelWithHeader>
</template>

<script>
import reportParameters from '@components/reports/reportParameters'
import downloadButtons from '@components/reports/downloadButtons'
import {get, sync, commit, call} from 'vuex-pathify'
import {authComputed} from '@state/helpers'
import download from 'downloadjs'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat) // needed for custom date parsing
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(LocalizedFormat) // needed to use 'L' as a format option
import useDisplayFormatters from '@composables/useDisplayFormatters'
export default {
  name: 'canned-report-grid',
  setup(props, context) {
    const {formatItemField, splitTitleCase} = useDisplayFormatters()
    return {
      formatItemField,
      splitTitleCase,
    }
  },
  data() {
    return {
      selectedReport: {},
      searchParams: {},
      cannedReports: [],
      valid: false,
      selectedMonth: 0,
      selectedYear: 0,
      includeDetails: false,
      startDate: dayjs()
        .subtract(1, 'month')
        .format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
    }
  },
  created() {
    this.getCannedReportList()
    this.loadLuExciseTransactionType()
    this.loadLuExciseProjectionType()
    this.loadLuReport()
  },
  watch: {
    reportParameters(nval, oval) {
      this.setDefaultParameters()
    },
    selectedReport(nval, oval) {
      console.green(nval)
      if (!nval.legacy) {
        this.selectedCannedReport = nval
      } else {
        this.selectedCannedReport = {}
      }
    },
    selectedCannedReport(nval, oval) {
      // console.green(nval)
      // this.selectedCannedReport = nval
      if (
        nval.reportDefaults &&
        nval.reportDefaults.ReportSections &&
        nval.reportDefaults.ReportSections.length > 0
      ) {
        this.sectionId = nval.reportDefaults.ReportSections[0].ID
      } else {
        this.sectionId = null
      }
      this.loadCannedReportGridData()
    },
    searchProjectId(nval, oval) {
      this.searchParams.projectId = nval
      if (nval) {
        this.loadSelectedProject(nval).then(() => {
          this.loadSelectedProjectExciseStratum()
        })
      }
    },
    searchStartDate(nval, oval) {
      this.searchParams.startDate = nval
    },
    searchEndDate(nval, oval) {
      this.searchParams.endDate = nval
    },
    searchStratumIds(nval, oval) {
      this.searchParams.stratumIds = nval
    },
    searchProjectionTypeId(nval, oval) {
      this.searchParams.projectionTypeId = nval
    },
    searchTransactionTypeId(nval, oval) {
      this.searchParams.transactionTypeId = nval
    },
    searchLicenseNumber(nval, oval) {
      this.searchParams.licenseNumber = nval
    },
    searchRidNumber(nval, oval) {
      this.searchParams.ridNumber = nval
    },
    groupByProperty(nval, oval) {
      if (nval && !oval) {
        // this sets the sort by to the date (asc) if we are grouping the report
        // if we are adding another canned report that does grouping we may want to do a check for just the review of transactions report
        this.searchSortBy = ['InvoiceDate']
        this.searchSortDesc = [false]
      }
      this.searchParams.groupByProperty = nval
    },
  },
  components: {
    reportParameters,
    downloadButtons,
  },
  computed: {
    ...authComputed,
    luReport: get('luReport/luReport'),
    ...get('cannedReport', [
      'reportParameters',
      'gridData', //entries in reportresult
      'loading',
      'headerItems', // from reportresult
      'formatFields', // from reportresult
    ]),
    ...get('project', ['selectedProjectExciseStratum']),
    ...get('luExciseTransactionType', ['luExciseTransactionType']),
    ...get('luExciseProjectionType', ['luExciseProjectionType']),
    ...sync('cannedReport', [
      'selectedCannedReport',
      'searchTotal',
      'searchPage',
      'searchSortBy',
      'searchDescending',
      'groupByProperty',
      'groupByDescending',
      'searchRowsPerPage',
      'sectionId',
    ]),
    searchProjectId: sync('cannedReport/projectId'),
    searchStratumIds: sync('cannedReport/stratumIds'),
    searchTransactionTypeId: sync('cannedReport/transactionTypeId'),
    searchProjectionTypeId: sync('cannedReport/projectionTypeId'),
    searchLicenseNumber: sync('cannedReport/licenseNumber'),
    searchRidNumber: sync('cannedReport/ridNumber'),
    searchStartDate: sync('cannedReport/startDate'),
    searchEndDate: sync('cannedReport/endDate'),
    availableReports() {
      let legacy = this.luReport
        .filter((item) => {
          return (
            item.IsActive &&
            (this.isSuperUser ||
              item.DivisionID == null ||
              item.DivisionID == this.currentUser.DivisionID)
          )
        })
        .map((x) => {
          return {
            ...x,
            isLegacy: true,
            Description: x.ReportName,
          }
        })
      let cannedReports = this.cannedReports
        .filter((x) => x.IsActive)
        .map((x) => {
          return {
            ...x,
            legacy: false,
          }
        })
      return cannedReports.concat(legacy)
    },
    headers() {
      var groupByProperties = this.selectedCannedReport.reportParameters.find(
        (i) => i.name == 'groupByProperty'
      )
      if (
        groupByProperties &&
        groupByProperties.options &&
        groupByProperties.options.list
      ) {
        groupByProperties = groupByProperties.options.list
      }
      let headers = this.headerItems.map((item) => {
        return {
          text: this.splitTitleCase(item),
          align: 'center',
          value: item,
          visible: true,
          groupable: groupByProperties
            ? groupByProperties.findIndex((i) => i.stringValue == item) > -1
            : false,
          //todo: set sortable property
        }
      })
      return headers
    },
    showGroupBy() {
      return this.headers.filter((x) => x.groupable).length > 0
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    //note: this is totally lame, but if I try to use a string literal in the v-slot vscode reformats it to urlencoded
    // this was tested with v-slot:[`item.${field}`] and vscode/vetur? converts to v-slot:[&#x60;item.${field}&#x60;] :(
    mappedFormats() {
      let slotFormat = {}
      for (var key in this.formatFields) {
        if (this.formatFields.hasOwnProperty(key)) {
          slotFormat[`item.${key}`] = this.formatFields[key]
        }
      }
      return slotFormat
    },
    reportParameters() {
      if (
        this.selectedCannedReport &&
        this.selectedCannedReport.reportParameters
      ) {
        let params = JSON.parse(
          JSON.stringify(this.selectedCannedReport.reportParameters)
        )
        params.forEach((p) => {
          var list = []
          if (p.options && p.options.list && p.options.list.length > 0) {
            p.options.list = p.options.list.map((i) => {
              return {
                text: i.text,
                value: i.value ? i.value : i.stringValue,
              }
            })
          } else {
            if (p.name == 'stratumIds') {
              list = this.selectedProjectExciseStratum.filter((i) => i.IsActive)
              if (
                this.searchProjectionTypeId &&
                this.searchProjectionTypeId > 0
              ) {
                list = list.filter(
                  (i) => i.ProjectionTypeID == this.searchProjectionTypeId
                )
              }
            }
            if (p.name == 'transactionTypeId') {
              list = JSON.parse(JSON.stringify(this.luExciseTransactionType))
            }
            if (p.name == 'projectionTypeId') {
              list = JSON.parse(JSON.stringify(this.luExciseProjectionType))
            }
            if (list.length > 0) {
              p.options.list = this.filterList(p, list)
            }
          }
        })
        return params
      } else {
        return []
      }
    },
    needsParameters() {
      if (this.reportParameters && this.reportParameters.length > 0) {
        return true
      } else {
        return false
      }
    },
    availableCannedReports() {
      return this.cannedReports.filter((x) => x.IsActive)
    },
    selectedMonthIndex() {
      if (!!this.selectedMonth) {
        return this.monthList.indexOf(this.selectedMonth)
      } else {
        return 0
      }
    },
    monthList() {
      return dayjs.months()
    },
    yearList() {
      return _.range(
        dayjs().year(),
        dayjs()
          .add(-10, 'years')
          .year(),
        -1
      )
    },
    groupBy: {
      get() {
        if (this.groupByProperty) {
          return [this.groupByProperty]
        } else {
          return []
        }
      },
      set(nval) {
        let oval = this.groupByProperty
        console.devlog(nval, oval, !nval, !oval)
        if (nval) {
          if (Array.isArray(nval)) {
            if (nval.length > 0) {
              this.groupByProperty = nval[0]
            } else {
              this.groupByProperty = null
            }
          } else {
            this.groupByProperty = nval
          }
        } else {
          this.groupByProperty = null
        }
      },
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    loadLuReport: call('luReport/loadLuReport'),
    loadLuExciseTransactionType: call(
      'luExciseTransactionType/loadLuExciseTransactionType'
    ),
    loadLuExciseProjectionType: call(
      'luExciseProjectionType/loadLuExciseProjectionType'
    ),
    ...call('project', [
      'loadSelectedProject',
      'loadSelectedProjectExciseStratum',
    ]),
    ...call('cannedReport', [
      'loadCannedReportGridData',
      'downloadCannedReport',
    ]),
    filterList(param, list) {
      if (list && list.length > 0) {
        if (
          param &&
          param.options &&
          param.options.filterList &&
          param.options.filterList.length > 0
        ) {
          param.options.filterList.forEach((x) => {
            if (x.valueMatch) {
              let matchList = JSON.parse(x.valueMatch)
              if (matchList && matchList.length > 0) {
                list = list.filter((i) => matchList.includes(i[x.propertyName]))
              }
            }
          })
        }
        return list.map((x) => {
          return {
            text: x.Description,
            value: x.ID,
          }
        })
      }
    },

    getCannedReportList() {
      let url = `/Division/${this.currentUser.DivisionID}/CannedReport`
      if (this.isSuperUser) {
        //get all canned reports for super user?
        url = `/CannedReport`
      }
      this.$axios.get(url).then(
        (res) => {
          this.cannedReports = res.data
        },
        (error) => {
          this.handleError(error)
        }
      )
    },
    setDefaultParameters() {
      this.reportParameters.forEach((item) => {
        if (!this.searchParams[item.name] && item.defaultValue) {
          this.searchParams[item.name] = item.defaultValue
        }
      })
      if (this.searchParams.startDate) {
        this.searchStartDate = this.searchParams.startDate
      }
      if (this.searchParams.endDate) {
        this.searchEndDate = this.searchParams.endDate
      }
    },
    updateSearchParam(p) {
      if (p.name == 'startDate') {
        this.searchStartDate = p.val
      } else if (p.name == 'endDate') {
        this.searchEndDate = p.val
      } else if (p.name == 'stratumIds') {
        this.searchStratumIds = p.val
      } else if (p.name == 'transactionTypeId') {
        this.searchTransactionTypeId = p.val
      } else if (p.name == 'projectionTypeId') {
        this.searchProjectionTypeId = p.val
      } else if (p.name == 'groupByProperty') {
        this.groupByProperty = p.val
      } else if (p.name == 'projectId') {
        this.searchProjectId = p.val
      } else if (p.name == 'licenseNumber') {
        this.searchLicenseNumber = p.val
      } else if (p.name == 'ridNumber') {
        this.searchRidNumber = p.val
      }
    },
    async validate() {
      this.$refs.form.validate()
    },
    downloadReport(fileType) {
      this.validate().then(() => {
        if (this.valid) {
          this.downloadCannedReport({selectedFileTypeID: fileType.ID})
        }
      })
    },
    getCannedReport() {
      this.validate().then(() => {
        if (this.valid) {
          if (this.selectedReport.ShowMonths || this.selectedReport.ShowYears) {
            var startDate = dayjs([this.selectedYear, this.selectedMonthIndex])
            var endDate = dayjs(startDate).endOf('month')
          } else {
            var startDate = dayjs(this.startDate)
            var endDate = dayjs(this.endDate)
          }
          const url = `Report?id=${
            this.selectedReport.Id
          }&startDate=${startDate.format(
            'YYYY-MM-DD'
          )}&endDate=${endDate.format('YYYY-MM-DD')}
          &includeDetails=${this.includeDetails}`
          let headers = {responseType: 'blob'}
          this.$axios.get(url, headers).then(
            (res) => {
              const content = res.headers['content-type']
              const dispo = res.headers['content-disposition']
              var filename = ''
              if (dispo && dispo.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                var matches = filenameRegex.exec(dispo)
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, '')
                }
              }
              download(res.data, filename, content)
            },
            (error) => {
              this.handleError(error)
              // this.error = error
              // this.displayError = true
              console.error(error)
            }
          )
        }
      })
    },
  },
}
//report parameters is json frm the db it sets the default parameters (or calculates if it is a date)
// reportParameters(state) {
//   if (state.selectedReport.ReportParameters) {
//     var params = JSON.parse(state.selectedReport.ReportParameters)
//     params.forEach((item) => {
//       if (item.calculateDefault && item.defaultValue === null) {
//         console.devlog(item)
//         var periodDifference = item.calculateDefault.relatedPeriod
//         var period = 'M'
//         var startEndOf = 'month'
//         //we are looking at a monthly report
//         if (item.calculateDefault.frequencyID == 5) {
//           period = 'M'
//           startEndOf = 'month'
//         }
//         //todo: add other date calculation periods if used
//         var date = dayjs()
//         date = date.add(periodDifference, period)
//         if (item.calculateDefault.pointInPeriod == 0) {
//           date = date.startOf(startEndOf)
//         } else if (item.calculateDefault.pointInPeriod == 2) {
//           date = date.endOf(startEndOf)
//         }
//         item.defaultValue = date.format('YYYY-MM-DD')
//         // if (state[item.text] == null) {
//         //   state[item.text] = item.defaultValue
//         // }
//       }
//     })
//     console.devlog(params)
//     return params
//   } else {
//     return []
//   }
// },

// [{
//   "name": "startDate",
//   "type": "DateTime",
//   "label": "Start Date",
//   "defaultValue": null,
//   "calculateDefault": {
//       "frequencyID": 5,
//       "relatedPeriod": -1,
//       "pointInPeriod": 0
//   }
// }, {
//     "name": "endDate",
//     "type": "DateTime",
//         "label": "End Date",
//     "defaultValue": null,
//     "calculateDefault": {
//         "frequencyID": 5,
//         "relatedPeriod": -1,
//         "pointInPeriod": 2
//     }
// }]

// 1|Quarter-Hourly
// 2|Hourly
// 3|Daily
// 4|Weekly
// 5|Monthly
// 6|Quarterly
// 7|Semiannually
// 8|Annually
// 9|Biennial
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
