<template>
  <v-container fluid>
    <!-- <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Canned Reports
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-form
                    ref="form"
                    @submit.prevent
                    v-model="valid"
                    lazy-validation
                  >
                    <v-row>
                      <v-col sm="4">
                        <v-select
                          v-model="selectedReport"
                          :items="availableReports"
                          return-object
                          item-text="ReportName"
                          label="Report"
                          :rules="[(v) => !!v || 'required']"
                          clearable
                        ></v-select>
                      </v-col>
                      <v-col v-if="selectedReport.ShowMonths" sm="4">
                        <v-select
                          :items="monthList"
                          item-value="index"
                          v-model="selectedMonth"
                          label="Month"
                          :rules="[(v) => !!v || 'required']"
                          clearable
                        ></v-select>
                      </v-col>
                      <v-col v-if="selectedReport.ShowYears" sm="4">
                        <v-select
                          :items="yearList"
                          v-model="selectedYear"
                          label="Year"
                          :rules="[(v) => !!v || 'required']"
                          clearable
                        ></v-select>
                      </v-col>
                      <v-col
                        v-if="
                          selectedReport.Id > 0 &&
                          !selectedReport.ShowMonths &&
                          !selectedReport.ShowYears
                        "
                        sm="12"
                        md="3"
                      >
                        <BaseDatePickerWithText
                          label="Start Date"
                          v-model="startDate"
                        ></BaseDatePickerWithText>
                      </v-col>
                      <v-col
                        v-if="
                          selectedReport.Id > 0 &&
                          !selectedReport.ShowMonths &&
                          !selectedReport.ShowYears
                        "
                        sm="12"
                        md="3"
                      >
                        <BaseDatePickerWithText
                          label="End Date"
                          v-model="endDate"
                        ></BaseDatePickerWithText>
                      </v-col>
                      <v-col
                        v-if="
                          selectedReport.Id > 0 && selectedReport.ShowDetails
                        "
                      >
                        <v-checkbox
                          v-model="includeDetails"
                          label="As Pivot?"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
        <v-row justify="end">
          <v-col sm="auto">
            <v-btn @click="getCannedReport" color="primary" class="mt-4">
              <v-icon left>mdi-file-chart</v-icon>Generate Report
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->
    <CannedReportGrid></CannedReportGrid>
  </v-container>
</template>

<!-- SCRIPTS -->
<script>
// import {get, sync, commit, call} from 'vuex-pathify'
// import {authMethods, authComputed} from '@state/helpers'
import CannedReportGrid from '@components/reports/canned-report-grid'

// import {debounce, range} from 'lodash'
// import download from 'downloadjs'
// import dayjs from 'dayjs'
// import localeData from 'dayjs/plugin/localeData'
// dayjs.extend(localeData)
export default {
  name: 'Reports',
  components: {
    CannedReportGrid,
  },
  page: {
    title: 'Canned Reports',
  },
  data: () => ({
    // valid: false,
    // panel: [0, 1],
    // selectedReport: 0,
    // selectedMonth: 0,
    // selectedYear: 0,
    // includeDetails: false,
    // startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    // endDate: dayjs().format('YYYY-MM-DD'),
  }),
  created() {
    // this.loadLuReport()
  },
  computed: {
    // ...authComputed,
    // luReport: get('luReport/luReport'),
    // selectedMonthIndex() {
    //   if (!!this.selectedMonth) {
    //     return this.monthList.indexOf(this.selectedMonth)
    //   } else {
    //     return 0
    //   }
    // },
    // monthList() {
    //   return dayjs.months()
    // },
    // yearList() {
    //   return _.range(dayjs().year(), dayjs().add(-10, 'years').year(), -1)
    // },
    // availableReports() {
    //   let list = this.luReport.filter((item) => {
    //     return (
    //       item.IsActive &&
    //       (this.isSuperUser ||
    //         item.DivisionID == null ||
    //         item.DivisionID == this.currentUser.DivisionID)
    //     )
    //   })
    //   list.sort((a, b) => {
    //     let textField = 'ReportName'
    //     let sort = a[textField] > b[textField]
    //     return sort
    //   })
    //   return list
    // },
  },

  methods: {
    // handleError: call('errors/handleError'),
    // loadLuReport: call('luReport/loadLuReport'),
    // async validate() {
    //   this.$refs.form.validate()
    // },
    // getCannedReport() {
    //   this.validate().then(() => {
    //     if (this.valid) {
    //       if (this.selectedReport.ShowMonths || this.selectedReport.ShowYears) {
    //         var startDate = dayjs([this.selectedYear, this.selectedMonthIndex])
    //         var endDate = dayjs(startDate).endOf('month')
    //       } else {
    //         var startDate = dayjs(this.startDate)
    //         var endDate = dayjs(this.endDate)
    //       }
    //       const url = `Report?id=${
    //         this.selectedReport.Id
    //       }&startDate=${startDate.format(
    //         'YYYY-MM-DD'
    //       )}&endDate=${endDate.format('YYYY-MM-DD')}
    //       &includeDetails=${this.includeDetails}`
    //       let headers = {responseType: 'blob'}
    //       this.$axios.get(url, headers).then(
    //         (res) => {
    //           const content = res.headers['content-type']
    //           const dispo = res.headers['content-disposition']
    //           var filename = ''
    //           if (dispo && dispo.indexOf('attachment') !== -1) {
    //             var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    //             var matches = filenameRegex.exec(dispo)
    //             if (matches != null && matches[1]) {
    //               filename = matches[1].replace(/['"]/g, '')
    //             }
    //           }
    //           download(res.data, filename, content)
    //         },
    //         (error) => {
    //           this.handleError(error)
    //           // this.error = error
    //           // this.displayError = true
    //           console.error(error)
    //         }
    //       )
    //     }
    //   })
    // },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>
