import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePanelWithHeader',{attrs:{"headerText":"Canned Reports"}},[_c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"label":"Select Report","items":_vm.availableReports,"return-object":"","item-text":"Description"},model:{value:(_vm.selectedReport),callback:function ($$v) {_vm.selectedReport=$$v},expression:"selectedReport"}})],1),(
        !_vm.selectedReport.isLegacy &&
          _vm.selectedCannedReport.FileTypes &&
          _vm.selectedCannedReport.FileTypes.length > 0
      )?_c('downloadButtons',{attrs:{"fileTypes":_vm.selectedCannedReport.FileTypes},on:{"downloadReport":_vm.downloadReport}}):_vm._e(),(_vm.selectedReport.isLegacy)?_c(VCol,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.getCannedReport}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-chart")]),_vm._v("Generate Report ")],1)],1):_vm._e()],1),_c(VForm,{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.reportParameters.length > 0)?_c('reportParameters',{attrs:{"reportParameters":_vm.reportParameters,"searchParams":_vm.searchParams},on:{"updateSearchParam":_vm.updateSearchParam}}):_vm._e(),(_vm.selectedReport.isLegacy)?_c(VRow,[(_vm.selectedReport.ShowMonths)?_c(VCol,{attrs:{"sm":"4"}},[_c(VSelect,{attrs:{"items":_vm.monthList,"item-value":"index","label":"Month","rules":[function (v) { return !!v || 'required'; }],"clearable":""},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}})],1):_vm._e(),(_vm.selectedReport.ShowYears)?_c(VCol,{attrs:{"sm":"4"}},[_c(VSelect,{attrs:{"items":_vm.yearList,"label":"Year","rules":[function (v) { return !!v || 'required'; }],"clearable":""},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}})],1):_vm._e(),(
          _vm.selectedReport.Id > 0 &&
            !_vm.selectedReport.ShowMonths &&
            !_vm.selectedReport.ShowYears
        )?_c(VCol,{attrs:{"sm":"12","md":"3"}},[_c('BaseDatePickerWithText',{attrs:{"label":"Start Date"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1):_vm._e(),(
          _vm.selectedReport.Id > 0 &&
            !_vm.selectedReport.ShowMonths &&
            !_vm.selectedReport.ShowYears
        )?_c(VCol,{attrs:{"sm":"12","md":"3"}},[_c('BaseDatePickerWithText',{attrs:{"label":"End Date"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1):_vm._e(),(_vm.selectedReport.Id > 0 && _vm.selectedReport.ShowDetails)?_c(VCol,[_c(VCheckbox,{attrs:{"label":"As Pivot?"},model:{value:(_vm.includeDetails),callback:function ($$v) {_vm.includeDetails=$$v},expression:"includeDetails"}})],1):_vm._e()],1):_vm._e()],1),_c(VRow,[_c(VCol,{attrs:{"sm":"12","md":"6"}},[(
          _vm.selectedCannedReport &&
            _vm.selectedCannedReport.reportDefaults &&
            _vm.selectedCannedReport.reportDefaults.ReportSections &&
            _vm.selectedCannedReport.reportDefaults.ReportSections.length > 0
        )?_c(VSelect,{attrs:{"label":"Show Section","items":_vm.selectedCannedReport.reportDefaults.ReportSections,"item-text":"Name","item-value":"ID"},model:{value:(_vm.sectionId),callback:function ($$v) {_vm.sectionId=$$v},expression:"sectionId"}}):_vm._e()],1)],1),(_vm.selectedCannedReport.ID)?_c('BaseServerSyncDataTable',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.gridData,"searchTotal":_vm.searchTotal,"loading":_vm.loading,"searchSortBy":_vm.searchSortBy,"searchDescending":_vm.searchDescending,"searchPage":_vm.searchPage,"searchRowsPerPage":_vm.searchRowsPerPage,"multi-sort":"","show-group-by":_vm.showGroupBy,"groupBy":_vm.groupBy,"groupDesc":_vm.groupByDescending},on:{"update:searchSortBy":function($event){_vm.searchSortBy=$event},"update:search-sort-by":function($event){_vm.searchSortBy=$event},"update:searchDescending":function($event){_vm.searchDescending=$event},"update:search-descending":function($event){_vm.searchDescending=$event},"update:searchPage":function($event){_vm.searchPage=$event},"update:search-page":function($event){_vm.searchPage=$event},"update:searchRowsPerPage":function($event){_vm.searchRowsPerPage=$event},"update:search-rows-per-page":function($event){_vm.searchRowsPerPage=$event},"update:groupBy":function($event){_vm.groupBy=$event},"update:group-by":function($event){_vm.groupBy=$event},"update:groupDesc":function($event){_vm.groupByDescending=$event},"update:group-desc":function($event){_vm.groupByDescending=$event}},scopedSlots:_vm._u([_vm._l((_vm.mappedFormats),function(format,field){return {key:field,fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm.formatItemField(item, field, format)))]}}})],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }